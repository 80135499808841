import React, { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Services from "./components/Services";
import CallToAction from "./components/CalltoAction";
import Career from "./pages/Career";

const App = () => {
  window.addEventListener("scroll", () => {
    const header = document.querySelector("header");
    if (window.scrollY > 0) {
      header.classList.add("scrolled");
    } else {
      header.classList.remove("scrolled");
    }
  });
  // Loading state
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading time (e.g., 2 seconds)
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    return () => clearTimeout(timer); // Cleanup timer
  }, []);

  return (
    <div>
      {isLoading ? (
        <LoadingScreen /> // Show loading screen while booting
      ) : (
        <Router>
          <Header />
          <main>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/career" element={<Career />} />
            </Routes>
          </main>
          <Footer />
        </Router>
      )}
    </div>
  );
};

// Loading screen component with animations
const LoadingScreen = () => (
  <div className="loading-screen">
    <h2>Loading... Please wait</h2>
    <div className="loading-animations">
      {/* <ReactLoading type="balls" color="#0000FF" height={100} width={50} /> */}
      {/* <ReactLoading type="bars" color="#0000FF" height={100} width={50} /> */}
      <ReactLoading type="bubbles" color="#ffa200" height={100} width={50} />
      {/* <ReactLoading
        type="spinningBubbles"
        color="#0000FF"
        height={100}
        width={50}
      /> */}
    </div>
  </div>
);
const Home = () => (
  <>
    <Hero />
    <OurApproach />
    <Services />
    <CallToAction />
    <Testimonials />
  </>
);

const Hero = () => (
  <section className="hero">
    <img
      src="./images/3302201.jpg" //background image
      alt="Background"
      className="background-image"
    />
    {/* <img
      src="./images/wave.png" //background image
      alt="Background"
      className="background-image-2"
    /> */}

    <div className="hero-content">
      <div className="hero-title">
        <h1>Redefining Digital Marketing for </h1>
        <h2 className="highlight">Modern Businesses</h2>
      </div>
      <p>
        Empowering brands with impactful strategies, creative storytelling, and
        data-driven insights.
      </p>
      <a href="#cta" className="cta-button">
        Get Started
      </a>
    </div>
    {/* SVG Wave Effect */}
    {/* Waves SVG */}
  </section>
);

const OurApproach = () => (
  <section className="our-approach">
    <div>
      <h2>Strategy Meets Innovation</h2>
      <p>
        At Innovator's Nexus, we’re committed to transforming your brand’s
        digital presence with tailored solutions that drive engagement and
        growth. Our approach combines creativity, analytics, and strategic
        insight to provide you with measurable results and a competitive edge.
      </p>
    </div>
  </section>
);

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const testimonials = [
    {
      name: "Sree Kalaimagal School",
      role: "Client",
      image: "/images/kalaimagal.jpg",
      feedback:
        "Innovator Nexus has been a game-changer for our digital presence. From strategy to execution, they exceeded our expectations every step of the way.",
    },
  ];

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <section className="testimonials" id="testimonials">
      <div className="heading">
        <h2>
          What's <span>Clients</span> Say?
        </h2>
      </div>
      <div className="carousel">
        <div
          className="carousel-container"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {testimonials.map((item, index) => (
            <div key={index} className="carousel-item">
              <img src={item.image} alt={item.name} />
              <h3>{item.name}</h3>
              <h4>{item.role}</h4>
              <p>{item.feedback}</p>
            </div>
          ))}
        </div>
        <div className="carousel-controls">
          <button className="control-button" onClick={goToPrevious}>
            ❮
          </button>
          <button className="control-button" onClick={goToNext}>
            ❯
          </button>
        </div>
      </div>
    </section>
  );
};

export default App;
