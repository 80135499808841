import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

export default function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleHamburgerClick = () => {
    setMenuOpen((prev) => !prev);
  };

  const handleScrollToSection = (sectionId, e) => {
    e.preventDefault(); // Prevent default behavior for anchor tag
    if (location.pathname !== "/") {
      // Navigate to the home page first, then scroll
      navigate(`/#${sectionId}`);
    } else {
      // Scroll to the section directly on the current page
      const section = document.querySelector(`#${sectionId}`);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
    // Close the menu after clicking
    setMenuOpen(false);
  };

  return (
    <header>
      {/* Logo */}
      <Link className="logo" to="/">
        <img alt="Innovator's Nexus Logo" src="/innovator_s_nexus_logo.png" />
        <h2>Innovator Nexus</h2>
      </Link>

      {/* Hamburger Icon */}
      <div
        className={`hamburger ${menuOpen ? "open" : ""}`}
        onClick={handleHamburgerClick}
        role="button"
        aria-expanded={menuOpen}
        tabIndex="0"
      >
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>

      {/* Navigation Links */}
      <nav className={menuOpen ? "open" : ""}>
        <a
          href="#services"
          onClick={(e) => handleScrollToSection("services", e)}
          aria-label="Services"
          role="button"
          tabIndex="0"
        >
          Services
        </a>
        <Link
          to="/career"
          aria-label="Career"
          onClick={() => setMenuOpen(false)}
        >
          Career
        </Link>
        <a
          href="#footer"
          onClick={(e) => handleScrollToSection("footer", e)}
          aria-label="About Us"
          role="button"
          tabIndex="0"
        >
          About Us
        </a>
        <a
          href="#testimonials"
          onClick={(e) => handleScrollToSection("testimonials", e)}
          aria-label="Testimonials"
          role="button"
          tabIndex="0"
        >
          Testimonials
        </a>
      </nav>

      <div className="contact-button">
        <a href="#cta" onClick={(e) => handleScrollToSection("cta", e)}>
          Contact us
        </a>
      </div>
    </header>
  );
}
