import React from "react";
import styles from "./Career.module.css";

// const Career = () => {
//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     phone: "",
//     role: "",
//     city: "",
//     resume: null,
//   });

//   const handleChange = (e) => {
//     const { name, value, files } = e.target;
//     setFormData({
//       ...formData,
//       [name]: name === "resume" ? files[0] : value,
//     });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     // Sending first email to the admin
//     emailjs
//       .send(
//         "gmail", // Service ID
//         "admin_mail", // Template ID for admin email
//         {
//           to_name: "Admin", // Admin's name or greeting (optional)
//           from_name: formData.from_name, // User's name from form data
//           user_email: formData.email, // User's email for reference
//           mobile: formData.mobile, // User's mobile (optional)
//           message: formData.message, // Message content from user
//         },
//         // "5Bg6X9nUDVeHR5YU5" // personal
//         "ejspucaxOGA1LuWup" //company
//       )
//       .then((response) => {
//         console.log(
//           "Email to admin sent successfully!",
//           response.status,
//           response.text
//         );

//         // After sending to admin, send the confirmation email to the user
//         return emailjs.send(
//           "gmail", // Service ID (same)
//           "template_response_email", // Template ID for the user confirmation email
//           {
//             to_name: formData.from_name, // User's name for personalized greeting
//             user_email: formData.email, // Dynamic "To Email" for user
//             reply_to: "contact@yourcompany.com", // Company's contact email for replies
//             message:
//               "Thank you for reaching out! We will get back to you shortly.",
//           },
//           // "5Bg6X9nUDVeHR5YU5" // personal EmailJS User ID
//           "ejspucaxOGA1LuWup" //company
//         );
//       })
//       .then((response) => {
//         console.log(
//           "Confirmation email to user sent successfully!",
//           response.status,
//           response.text
//         );
//         alert("Message sent successfully!");

//         // Reset form after both emails are sent
//         setFormData({
//           to_name: "",
//           from_name: "",
//           email: "",
//           mobile: "",
//           message: "",
//         });
//       })
//       .catch((err) => {
//         console.error("Failed to send message:", err);
//         alert("Failed to send message. Please try again later.");
//       });
//   };

//   return (
//     <div
//       style={{
//         background:
//           "url('/images/background_2.jpg') no-repeat center center/cover",

//         height: "auto", // Full screen height
//         display: "flex",
//         flexDirection: "column",
//         padding: "1rem",
//       }}
//     >
//       <section className={styles["career-container"]}>
//         <h1>Careers</h1>
//         <p>
//           We are looking for enthusiastic and skilled individuals to join our IT
//           team. If you love technology and solving problems, this is the place
//           for you! We offer a friendly and creative environment where you can
//           grow your skills and advance your career.
//         </p>
//         <form onSubmit={handleSubmit}>
//           <label>
//             <span className="required-label">Name</span>
//             <input
//               type="text"
//               name="name"
//               value={formData.name}
//               onChange={handleChange}
//               required
//             />
//           </label>
//           <label>
//             <span className="required-label">Email Address</span>
//             <input
//               type="email"
//               name="email"
//               value={formData.email}
//               onChange={handleChange}
//               required
//             />
//           </label>
//           <label>
//             <span className="required-label">Phone</span>
//             <input
//               type="tel"
//               name="phone"
//               value={formData.phone}
//               onChange={handleChange}
//               required
//             />
//           </label>
//           <label>
//             <span className="required-label">Role</span>
//             <select
//               name="role"
//               value={formData.role}
//               onChange={handleChange}
//               required
//             >
//               <option value="" disabled></option>
//               <option value="Front-end Developer">Front-end Developer</option>
//               <option value="Back-end Developer">Back-end Developer</option>
//               <option value="Full-stack Developer">Full-stack Developer</option>
//               <option value="UI/UX Designer">UI/UX Designer</option>
//             </select>
//           </label>
//           <label>
//             <span className="required-label">City</span>
//             <input
//               type="text"
//               name="city"
//               value={formData.city}
//               onChange={handleChange}
//               required
//             />
//           </label>
//           <label>
//             <span className="required-label">Resume</span>
//             <input
//               type="file"
//               name="resume"
//               accept=".pdf,.doc,.docx"
//               onChange={handleChange}
//               required
//             />
//           </label>
//           <button type="submit">Submit</button>
//         </form>
//       </section>
//     </div>
//   );
// };

const Career = () => {
  const roles = [
    {
      role: "Digital Marketing Specialist",
      preferredSkills: "SEO, PPC, Social Media Marketing, Email Marketing",
    },
    {
      role: "Content Creator",
      preferredSkills:
        "Copywriting, Visual Content Creation, Content Strategy, Editing",
    },
    {
      role: "Software Developer",
      preferredSkills:
        "Programming (Python, JavaScript), Web Development, Software Testing, Git",
    },
    {
      role: "Business Analyst",
      preferredSkills:
        "Requirements Gathering, Data Analysis, Process Improvement, Stakeholder Communication",
    },
    {
      role: "Videographer and Editor",
      preferredSkills:
        "Camera Operation, Video Editing Software, Storyboarding, Post-Production Techniques",
    },
  ];

  return (
    <div>
      <img
        src="./images/career-background-2.jpg" //background image
        alt="Background"
        className={styles["background-image-career"]}
      />
      <section className={styles["career-container"]}>
        <h1>Careers</h1>
        <p>
          We are looking for enthusiastic and skilled individuals to join our IT
          team. If you love technology and solving problems, this is the place
          for you! We offer a friendly and creative environment where you can
          grow your skills and advance your career.
        </p>

        <table className={styles["career-table"]}>
          <thead>
            <tr>
              <th>Role</th>

              <th>Preferred Skills</th>
              <th>Apply</th>
            </tr>
          </thead>
          <tbody>
            {roles.map((item, index) => (
              <tr key={index}>
                <td>{item.role}</td>

                <td>{item.preferredSkills}</td>
                <td>
                  {" "}
                  <a
                    href="http://forms.gle/NvfDYjAWFhfR3XHV7"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles["career-link"]}
                  >
                    Apply Now
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
    </div>
  );
};

export default Career;
